html, body
{
    width: 100%;
    height: 100%;
}

body
{
    width: 100%;
    height: 100%;
    background-attachment: fixed;
    background-color: #15034f;
}

.App {
  text-align: center;
  background-color: #15034f;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.selector-image {
    width: 12px;
    transform: rotate(90deg);
}

.logo {
  width: 30px;
  height: 30px;
}

.videowrapper {
  float: none;
  clear: both;
  width: 100%;
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
  margin-bottom: 50px;
}
.videowrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

a:link { text-decoration: none;
  color: white;
 }

.network-icon {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: white;
}

@font-face {
  font-family: "RussoOne";
  font-weight: 500;
  font-display: auto;
  src: local('RussoOne'), url('/public/assets/fonts/RussoOne-Regular.ttf') format("woff");
}

